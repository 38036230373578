import React from "react";
import styles from "./BannerCTAs.module.css";
import Link from "next/link";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const BannerCTAsView = ({
  button1_text = "",
  button1_link = "",
  button2_text = "",
  button2_link = "",
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.ctaBtns}>
      {button1_text && (
        <button
          onClick={scrollToTop}
          data-analytics={[UIElements.BUTTON, "career"]}
          className={styles["cta-1"]}
        >
          {button1_text}
        </button>
      )}
      {button2_text && button2_link && (
        <Link
          className={styles["cta-2"]}
          href={button2_link}
          data-analytics={[UIElements.LINK, "career"]}
        >
          {button2_text}
        </Link>
      )}
    </div>
  );
};

export default BannerCTAsView;
