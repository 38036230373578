import dynamic from "next/dynamic";
import React from "react";
import styles from "./HaloTxtImgB.module.css";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import BannerCTAsView from "./BannerCTAs/BannerCTAs.view";

const BannerImage = dynamic(() => import("./BannerImage"));
const BannerDetails = dynamic(() =>
  import("./BannerDetails/BannerDetails.view")
);

const HaloTxtImgB = ({
  image,
  bgColor,
  borderColor,
  heading = "",
  description = "",
  button1_text = "",
  button1_link = "",
  button2_text = "",
  button2_link = "",
  showOn,
  imgRTL = true,
}) => {
  const desktopLayout = imgRTL ? styles.desktopRight : styles.desktopLeft;
  const bgStyle = bgColor
    ? { backgroundColor: bgColor }
    : { backgroundColor: "transparent" };
  const borderStyle = borderColor
    ? { borderColor: borderColor, border: "1px solid" }
    : {};

  return (
    <UseComponentVisibility conditions={showOn}>
      <section
        data-outscal-section
        className={`${styles.root} ${desktopLayout}`}
        style={{ ...bgStyle, ...borderStyle }}
      >
        <div className={styles.bannerDetails}>
          <BannerDetails heading={heading} description={description} />
        </div>
        <div className={styles.bannerImage}>
          <BannerImage image={image} />
        </div>
        <div className={styles.bannerCTAs}>
          <BannerCTAsView
            button1_text={button1_text}
            button1_link={button1_link}
            button2_text={button2_text}
            button2_link={button2_link}
          />
        </div>
      </section>
    </UseComponentVisibility>
  );
};

export default HaloTxtImgB;
